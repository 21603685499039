import UIkit from 'uikit'
import CatalogItemClass from './catalog-item.js'
import delayedFunction from '../libs/delayedFunction.js'

class SearchFullClass {

  contextChange() {
    let newContext = this.$context.val().trim()
    console.log('contextChange',this.context,newContext)
    if (newContext === this.context) {
      return;
    }
    this.context = newContext;
    this.$result.hide()
    if (this.searchAbort) {
      this.searchAbort.abort()
    }

    if (this.context == '') {
      return;
    }
    this.$loader.show()
    delayedFunction.start(this.searchStart,800)
  }

  async searchStartProto() {
    if (this.context == '') {
      this.$loader.hide()
      return;
    }

    this.searchAbort = new AbortController()
    console.log('after abort')
    try {
      await Promise.all([
        this.loadCategories(),
        this.loadItems(),
      ])
    } catch (e) {
      if (e.type !== 'abort') {
        throw e
      }
      return
    }

    this.$loader.hide()
    this.$result.show();
  }

  async loadCategories() {
    this.$groups.hide()
    let jsonResult = await $I.jsonAsync('/search/groups/',{
      context: this.context,
    },{
      throwErrors: true,
      signal:  this.searchAbort.signal
    })
    console.log('groups',jsonResult);
    if (!jsonResult.groups.length) return;
    this.$groupItems.empty();
    for (const item of jsonResult.groups) {
      this.$groupItems.append('<li><a href="/catalog/category/'+item.CAT_ALIAS+'/'+item.ALIAS+'/">'+item.TITLE+'</a></li>')
    }
    this.$groups.show()
  }

  async loadItems() {
    this.$items.empty();
    let html = await $I.fetch('/search/items_full/',{
      context:this.context      
    },{
      throwErrors: true,
      signal: this.searchAbort.signal
    })
    console.log('html',html)
    this.$items.html(html)
    this.searchItems = [];
    this.$items.find('.shop-item').each((i,itm)=>{
      this.searchItems.push( new CatalogItemClass($(itm)) );
    });
  }

  start(context) {
    this.context = context;
    this.$context.val(context);
    UIkit.modal($("#search-modal")[0]).show();
    this.searchStart();
  }

  init() {
    this.$context = $('#search-full-context'); //Поле контекста
    this.$result = $('#search-full-result');
    this.$items = $('#search-full-items');
    this.$loader = $('#search-full-loader');
    this.$groups = $('#search-full-groups');
    this.$groupItems = $('#search-full-group-items');

    console.log('search full',this);    
    this.$context.on("keydown",(e)=>{
      setTimeout(()=>{
        this.contextChange();
      },0)
    });
    //Форма поля контекста
    $("#search-full-form").on("submit",(ev)=>{
      ev.preventDefault();
      this.searchStart();
    });
    //Кнопка на раме
    $("#search-mini-button").on("click",()=>{
      this.start('')
    })
  }

  constructor() {
    this.searchStart = ()=>{ return this.searchStartProto() }
    this.context = '';
    this.searchItems = [];
    this.searchAbort = null
  }

}

export default new SearchFullClass();
