//Полифиллы и прочее
//import "core-js/stable";
//import "regenerator-runtime/runtime";
require("setimmediate");

//UIKit
import UIkit from 'uikit'
import Icons from 'uikit/dist/js/uikit-icons'
UIkit.use(Icons);
import offcanvas2 from './includes/offcanvas2'
UIkit.component('offcanvas2', offcanvas2)

//JQuery plugins
require('jquery-mask-plugin');
//require('jquery.maskedinput');//TODO: Избавится в пользу inputmask
require('jquery.mmenu');
require('air-datepicker');
require('./libs/input-stepper.js');
require('./libs/number-divider.js');
require('suggestions-jquery');

import autosize from 'autosize'
import Inputmask from "inputmask"
import catalogTreeModel from './includes/catalog-tree-model.js'
import catalogTreeGenerator from './includes/catalog-tree-generator.js'
import CatalogItemClass from './includes/catalog-item.js'
//import SearchMini from './includes/search-mini.js'
import SearchFull from './includes/search-full.js'
import CartMini from './includes/cart-mini.js'
import FastOrderModal from './includes/fast-order-modal.js'
import AuthModal from './includes/auth-modal.js'
import {unregisterPush} from './includes/register-push.js'
import {checkAppToken,clearAppToken} from './includes/app-token.js'

$I.showError = (errorType,errorCode,errorStr)=>{
  if (errorType == "auth") {
    location.href = '/';
    return;
  }
  if (errorType != "user") {
    errorStr = "Произошла внутренняя ошибка,\nпопробуйте выполнить операцию позже...";
  }
  UIkit.modal.alert(errorStr);
};

async function initCatalogTree() {
  let $container = $('#catalog-nav');

  let json = await catalogTreeModel.get();
  console.log('tree',json);

  let generator = new catalogTreeGenerator();
  generator.render($container,json.tree);
  //setTimeout(function() {

    //  меню каталога
    $container.mmenu({
      extensions: [
        'theme-white',
        'fx-menu-slide',
        'border-full'
      ],
      counters: true,
      navbar: {
        'title': 'Отделы магазина'
      },
      // iconbar: {
      //   use: true,
      //   top: [
      //     '<a href="/"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"\n' +
      //     '\t width="20" height="20" viewBox="0 0 460.298 460.297" style="enable-background:new 0 0 460.298 460.297;"\n' +
      //     '\t xml:space="preserve">\n' +
      //     '\t\t<path fill="#80bb66" d="M230.149,120.939L65.986,256.274c0,0.191-0.048,0.472-0.144,0.855c-0.094,0.38-0.144,0.656-0.144,0.852v137.041\n' +
      //     '\t\t\tc0,4.948,1.809,9.236,5.426,12.847c3.616,3.613,7.898,5.431,12.847,5.431h109.63V303.664h73.097v109.64h109.629\n' +
      //     '\t\t\tc4.948,0,9.236-1.814,12.847-5.435c3.617-3.607,5.432-7.898,5.432-12.847V257.981c0-0.76-0.104-1.334-0.288-1.707L230.149,120.939\n' +
      //     '\t\t\tz"/>\n' +
      //     '\t\t<path fill="#80bb66"  d="M457.122,225.438L394.6,173.476V56.989c0-2.663-0.856-4.853-2.574-6.567c-1.704-1.712-3.894-2.568-6.563-2.568h-54.816\n' +
      //     '\t\t\tc-2.666,0-4.855,0.856-6.57,2.568c-1.711,1.714-2.566,3.905-2.566,6.567v55.673l-69.662-58.245\n' +
      //     '\t\t\tc-6.084-4.949-13.318-7.423-21.694-7.423c-8.375,0-15.608,2.474-21.698,7.423L3.172,225.438c-1.903,1.52-2.946,3.566-3.14,6.136\n' +
      //     '\t\t\tc-0.193,2.568,0.472,4.811,1.997,6.713l17.701,21.128c1.525,1.712,3.521,2.759,5.996,3.142c2.285,0.192,4.57-0.476,6.855-1.998\n' +
      //     '\t\t\tL230.149,95.817l197.57,164.741c1.526,1.328,3.521,1.991,5.996,1.991h0.858c2.471-0.376,4.463-1.43,5.996-3.138l17.703-21.125\n' +
      //     '\t\t\tc1.522-1.906,2.189-4.145,1.991-6.716C460.068,229.007,459.021,226.961,457.122,225.438z"/>\n' +
      //     '</svg></a>'
      //   ]
      // },
      navbars: [
        {
          position: 'top',
          content: [
            '<a href="/"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"\n' +
            '\t width="20" height="20" viewBox="0 0 460.298 460.297" style="enable-background:new 0 0 460.298 460.297;"\n' +
            '\t xml:space="preserve">\n' +
            '\t\t<path fill="#80bb66"  d="M230.149,120.939L65.986,256.274c0,0.191-0.048,0.472-0.144,0.855c-0.094,0.38-0.144,0.656-0.144,0.852v137.041\n' +
            '\t\t\tc0,4.948,1.809,9.236,5.426,12.847c3.616,3.613,7.898,5.431,12.847,5.431h109.63V303.664h73.097v109.64h109.629\n' +
            '\t\t\tc4.948,0,9.236-1.814,12.847-5.435c3.617-3.607,5.432-7.898,5.432-12.847V257.981c0-0.76-0.104-1.334-0.288-1.707L230.149,120.939\n' +
            '\t\t\tz"/>\n' +
            '\t\t<path fill="#80bb66"  d="M457.122,225.438L394.6,173.476V56.989c0-2.663-0.856-4.853-2.574-6.567c-1.704-1.712-3.894-2.568-6.563-2.568h-54.816\n' +
            '\t\t\tc-2.666,0-4.855,0.856-6.57,2.568c-1.711,1.714-2.566,3.905-2.566,6.567v55.673l-69.662-58.245\n' +
            '\t\t\tc-6.084-4.949-13.318-7.423-21.694-7.423c-8.375,0-15.608,2.474-21.698,7.423L3.172,225.438c-1.903,1.52-2.946,3.566-3.14,6.136\n' +
            '\t\t\tc-0.193,2.568,0.472,4.811,1.997,6.713l17.701,21.128c1.525,1.712,3.521,2.759,5.996,3.142c2.285,0.192,4.57-0.476,6.855-1.998\n' +
            '\t\t\tL230.149,95.817l197.57,164.741c1.526,1.328,3.521,1.991,5.996,1.991h0.858c2.471-0.376,4.463-1.43,5.996-3.138l17.703-21.125\n' +
            '\t\t\tc1.522-1.906,2.189-4.145,1.991-6.716C460.068,229.007,459.021,226.961,457.122,225.438z"/>\n' +
            '</svg></a>'
          ]
        }
      ]
    });

    var offCanvasMenu = $container.data("mmenu");
    console.log('offCanvasMenu', $container.data());

    if ((generator.curTreePanelId !== null) && (generator.catalogTreePanels[generator.curTreePanelId] !== undefined)) {
      offCanvasMenu.openPanel(generator.catalogTreePanels[generator.curTreePanelId]);
    }

    offCanvasMenu.close();

  //}, 300);

}

function initShowPassword() {
    // password show / hide
    var _toggle        = $('.password-toggle'),
        _inputPassword = $('.password-toggle + .uk-input');

    $(_toggle).on('click', function (el) {
      el.preventDefault();
      var _inputPasswordAttr = $(_inputPassword).attr('type');
      if (_inputPasswordAttr === 'password') {
        $(_inputPassword).attr('type', 'text');
        _removeClass(_toggle);
        $(_toggle).attr('uk-icon', 'icon: eye-open').addClass('uk-text-success');

      } else {
        $(_inputPassword).attr('type', 'password');
        _removeClass(_toggle);
        $(_toggle).attr('uk-icon', 'icon: eye-close').addClass('uk-text-danger');

      }
      function _removeClass(el) {
        $(el).removeClass('uk-text-danger uk-text-success');
      }
    });
}

$(document).ready(async function ($) {

  checkAppToken()

  $('.preloader').css({opacity:0});
  setTimeout(()=>{ $('.preloader').hide(); },400);

  // mask
  let phoneMask = new Inputmask({
    mask: "+7 (999) 999-99-99"
  })
  $('.phone').each((i,itm)=>{
    phoneMask.mask(itm);
  });
  // autosize
  autosize($('.autosize'));
  // number-divider
  $('.js-auto-numeric').divide({
    delimiter: ' ',
    divideThousand: false
  });

  let logoutProcess = false
  $('.logout').on('click',async (ev)=>{
    ev.preventDefault()
    if (logoutProcess) return;
    try {
      logoutProcess = true
      clearAppToken()
      let res = await $I.jsonAsync("/logout/logout/",{},true)
      appserver_token = res.token
      unregisterPush()
      location.href = '/'
    } catch(e) {
      logoutProcess = false
      throw e
    }
  })

  initShowPassword();
  initCatalogTree();
  //SearchMini.init();
  SearchFull.init();
  CartMini.init();
  FastOrderModal.init();
  AuthModal.init();
  window.AuthModal = AuthModal

  let catalogitems = [];
  $('.js-shop-item').each((i,itm)=>{
    catalogitems.push( new CatalogItemClass($(itm)) );
  })

  $("#app_favorites").on('click',(ev)=>{
    ev.preventDefault()
    if (!registred) {
      AuthModal.onSuccess = ()=>{
        registred = true
        location.href='/favorites/'
      } 
      AuthModal.open()      
    } else {
      location.href='/favorites/'
    }
  })

});
