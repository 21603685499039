import notify from './notify.js'

export function registerPush() {
  let tryIOS = window?.webkit?.messageHandlers?.setTokenHandler?.postMessage
  let tryAndroid = window?.app?.setToken
  console.log('registerPush',tryIOS,tryAndroid)
  if (tryIOS) {
    window.webkit.messageHandlers.setTokenHandler.postMessage(appserver_token)
  }
  if (tryAndroid) {
    window.app.setToken(appserver_token)
  }
}

export function unregisterPush() {
  let tryIOS = window?.webkit?.messageHandlers?.deleteTokenHandler?.postMessage
  let tryAndroid = window?.app?.deleteToken
  console.log('unregisterPush',tryIOS,tryAndroid)
  if (tryIOS) {
    window.webkit.messageHandlers.deleteTokenHandler.postMessage(appserver_token)
  }
  if (tryAndroid) {
    window.app.deleteToken(appserver_token)
  }
}