function appGetToken() {
    return new Promise((resolve,reject)=>{
        let tokenCallback = (token)=>{
            resolve(token)
        }
        window.setCarabiTokenDisplay = tokenCallback
        window.setCarabiTokenToJs = tokenCallback

        let tryIOS = window?.webkit?.messageHandlers?.tryGetCarabiTokenHandler?.postMessage
        let tryAndroid = window?.app?.tryGetCarabiToken
        console.log('appGetToken',tryIOS,tryAndroid)
        if (!tryIOS && !tryAndroid) {
            resolve(null)
        }
        if (tryIOS) {
            window.webkit.messageHandlers.tryGetCarabiTokenHandler.postMessage({})
        }
        if (tryAndroid) {
            window.app.tryGetCarabiToken()
        }
    })
}

function appSetToken(token) {
    let tryIOS = window?.webkit?.messageHandlers?.trySetCarabiTokenHandler?.postMessage
    let tryAndroid = window?.app?.setCarabiToken
    console.log('appSetToken',tryIOS,tryAndroid)
    if (tryIOS) {
        window.webkit.messageHandlers.trySetCarabiTokenHandler.postMessage(token)
    }
    if (tryAndroid) {
        window.app.setCarabiToken(token)
    }
}

export function clearAppToken() {
    appSetToken('')
}

export async function checkAppToken() {
    //let appToken = "bb3ae273ac3896fc42896d008feafc157865ec87edbbd4ba4a3d465d3b38d4ef"
    let appToken = await appGetToken()
    let phpToken = window.appserver_token
    let registred = window.registred
    console.log('checkAppToken',appToken,phpToken,registred)
    if (registred && (phpToken != appToken)) {
        console.log('Send to app new token',phpToken)
        appSetToken(phpToken)
        return
    }
    if (!registred && appToken) {
        try {
            let result = await $I.jsonAsync("/login/set_token/",{
                token: appToken
            },{ throwErrors:true });
            if (result.result !== 'OK') throw 'Result not Ok'
            //alert('reload!')
            location.reload()
        } catch(e) {
            console.log("Set app token error",e)
            appSetToken('')
        }
        return
    }
}